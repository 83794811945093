<template>
  <v-dialog v-model="dialog" persistent max-width="600px" style="z-index: 1">
    <v-card>
      <v-card-title class="flex-row justify-between d-flex app-bold-font">
        Coupon Code
        <v-btn icon @click="onClose"><v-icon>mdi-close</v-icon></v-btn>
      </v-card-title>
      <div style="min-height: 50px; max-height: 61vh; overflow: auto">
        <v-card-text class="app-regular-font">
          Click "OK" to reveal and copy the coupon code.
        </v-card-text>
        <div class="terms-container">
          <span class="terms-head">Terms & Conditions</span>
          <p class="terms">
            {{showCouponcodes.description}}
          </p>
        </div>
        <v-card-text class="justify-center py-0 d-flex flex-column">
          <div style="font-family: 'Poppins-Medium'; color: #1e1e1e">
            COUPON CODE
          </div>
          <v-text-field
            label="**************"
            solo
            v-model="couponCode"
            flat
            outlined
            class="mt-2 app-regular-font"
            dense
            readonly
            :disabled="!couponRevealed"
            @click="copyCouponCode"
          />
        <v-icon v-if="!couponRevealed" class="reveal" @click="showCoupon">mdi mdi-lock-open-variant-outline</v-icon>
        <v-icon v-else class="copy-icon" @click="copyCouponCode">mdi mdi-content-copy</v-icon>
        </v-card-text>

        <v-card-text v-if="message">
          <v-alert dense type="success" v-if="message && this.couponRevealed">{{ message }}</v-alert>
          <v-alert dense type="error" v-if="message && !this.couponRevealed">{{ message }}</v-alert>
        </v-card-text>
      </div>
      <v-card-actions class="flex-row justify-center pb-6 mt-3 d-flex">
        <v-btn
          color="#b5e539"
          @click="onClose"
          class="white--text"
          width="150"
          :loading="loading"
          >CLOSE</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";

export default {
  components: {},
  props: {
    dialog: {
      type: Boolean,
      default: false
    },
    showCouponcodes: {
      type: Object
    },
    onClose: {
      type: Function
    }
  },
  data() {
    return {
      message: null,
      loading: false,
      couponCode: "**************",
      couponRevealed: false
    };
  },
  methods: {
    ...mapActions("awards", {
      reedemCoupon: "reedemCoupon"
    }),
    onChange() {
      this.message = null;
    },
    async showCoupon() {
      if (
        this.showCouponcodes && this.showCouponcodes._id
      ) {
        
        const apiData = {
          award_id: this.showCouponcodes._id,
        };
     
        try {
          const response = await this.reedemCoupon(apiData);
        if (response && response.couponCode) {
            this.couponCode = response.couponCode; 
            this.couponRevealed = true;
          } else {
            throw new Error("Invalid response format");
          }
        } catch (error) {
          console.error("Error sending coupon:", error);
          this.message =
          this.message = error.response?.data.message || error.message;
      }
        //Emit the couponRevealed value to the parent component
       this.$emit("coupon-revealed", this.couponRevealed);
        //   this.onClose();
      }
      
    },
    async copyCouponCode() {
      if (!this.couponRevealed) return;

      try {
        await navigator.clipboard.writeText(this.couponCode);
        this.message = "Coupon code copied to clipboard!";
        setTimeout(() => {
          this.message = null;
        }, 2000);
      } catch (err) {
        console.error("Failed to copy: ", err);
        this.message = "Failed to copy coupon code!";
      }
    }
  },
  computed: {
    ...mapState("auth", {
      profile: "profile"
    }),
    ...mapGetters({
      timezone: "usersetting/getTimeZone"
    })
  },

};
</script>

<style scoped>
/* The same styles as before */
@media (max-width: 600px) {
  div :deep(.v-dialog) {
    margin: 0;
  }
}
@media (min-width: 600px) {
  .v-dialog__content {
    z-index: 2000001 !important;
  }
  div :deep(.v-dialog) {
    width: 70%;
    max-width: 900px;
    overflow-y: hidden;
  }
}
.v-dialog::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.v-dialog::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px light-grey;
  border-radius: 3px;
}
/* Handle */
.v-dialog::-webkit-scrollbar-thumb {
  background: #f0a000;
  border-radius: 3px;
}

/* Handle on hover */
.v-dialog::-webkit-scrollbar-thumb:hover {
  background: #f0a000;
}

.time-picker {
  width: 107px;
  border-color: #d094ea !important;
  border-width: 1px;
  height: 40px;
}
.time-picker :deep(input.display-time) {
  height: 40px !important;
  width: 100%;
  border-radius: 5px;
  border-color: rgba(0, 0, 0, 0.38);
  padding: 0 15px;
}
.time-picker :deep(input.display-time:hover) {
  border-color: currentColor;
}
.time-picker :deep(input.display-time:focus-visible) {
  border-color: #d094ea !important;
  border-width: 1px;
}
.time-picker :deep(.clear-btn) {
  padding: 5px 16px;
  margin-top: 5px;
}
.terms{
  font-size: 14px;
  color: #7D7C78;
  font-family: 'Poppins-Regular';
  color: #7d7c78;
  font-family: "Poppins-Regular";
}
.terms-container{
     background-color: #F4E7C0;
    padding: 7px 6px;
    background-color: #f4e7c0;
    padding: 7px 6px;
    border: 2px solid #ffcf50;
    margin: 5px 28px 5px 14px;
    border-radius: 13px;
  font-size: 16px;
  color: #F0A04B;
  font-family: 'Poppins-Regular';
  color: #f0a04b;
  font-family: "Poppins-Regular";
}
.reveal{
  color: #f0a04b;
  position: absolute !important;
  left: 550px !important;
  cursor: pointer;

}
.copy-icon{
  color: #F0A04B;
  position: absolute !important;
  left: 550px !important;
  cursor: pointer;
}
</style>
