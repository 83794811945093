<template>
  <v-card @click="goToDetails" style="width: 100%">
    <v-card-text class="flex-row mt-3 d-flex" style="width: 100%">
      <div>
        <v-img
          :src="require('@/assets/svg/awards.svg')"
          width="30"
          height="30"
          contain
        />
      </div>
      <div class="mx-3 d-flex flex-column" style="flex-grow:1">
        <span style="font-size: 16px; font-family: 'Poppins-SemiBold';">
          {{ award.title }}
        </span>
        <span
          style="color: #0a5b8a; font-size: 12px;font-family: 'Poppins-Regular';"
        >
          {{ formatDate(award.expiryDate) }}
        </span>
        <span> Goal: {{ award.goal_hours }} hrs </span>
      </div>
      <div v-if="profile.type == 'Volunteer' && award.reedemedUsers && award.reedemedUsers.some(coupon => coupon.redeemed_by === profile._id)">
        <div class="code-title">
          Coupon Code Revealed!
        </div>
        <div class="code" @click="copyCouponCode">
          {{ award.couponCode }}
        </div>
      </div>

      <div style="flex-shrink: 0" v-if="award.issuedBy == profile._id">
        <v-tooltip bottom >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              style="border-radius: 1px; border-color: #0a5b8a; border-style: solid; border-radius: 36px;"
              small
              @click.stop="onEdit"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon color="#0a5b8a" style="margin-top: 2px;" size="18"
                >mdi-pencil</v-icon
              >
            </v-btn>
          </template>
          Edit Award
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              style="border-radius: 1px; border-color: #0a5b8a; border-style: solid; border-radius: 36px;"
              small
              class="ml-2"
              @click.stop="onDelete"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon color="#0a5b8a" style="margin-top: 2px;" size="18"
                >mdi-delete</v-icon
              >
            </v-btn>
          </template>
          Delete Award
        </v-tooltip>
      </div>
    </v-card-text>
    <v-card-text class="pt-0 mt-0">
      <div class="position-relative">
        <span
          class="text-block"
          v-if="text_collapsed"
          v-linkified
          v-text="collapsed_text"
        />
        <span
          v-else
          v-text="award.description"
          class="text-block"
          v-linkified
        />
        <span
          class="url-button"
          @click.stop="text_collapsed = false"
          @mousedown.stop
          @touchstart.stop
          v-if="text_collapsed"
          x-small
          style="position: absolute; right: 18px; bottom: 0; background: white"
        >
          ...see more
        </span>
      </div>
      <div
        v-if="users.length > 0 && profileType != 'Volunteer'"
        class="mt-2 app-bold-font"
      >
        Eligible users
        <div v-for="user in users" :key="user._id" class="my-4">
          <editable-avatar
            :image="user.midThumbnail ? user.midThumbnail : user.thumbnail"
            :editable="false"
            :size="30"
            class="ml-2"
          />
          <span
            class="ml-2"
            style="font-family: 'Poppins-SemiBold'; color: #242f36"
          >
            {{ user.first_name }} {{ user.last_name }} -
            {{ formatMinutes(user.volunteered_hours) }} hrs
          </span>
        </div>
      </div>
      <div
        v-if="
          myHours >= parseInt(award.goal) * 60 && profileType == 'Volunteer'
        "
        style="font-family: 'Poppins-SemiBold';"
      >
        Eligible
      </div>
      <!-- && !award.couponCode.some(coupon => coupon.used && coupon.redeemed_by === profile._id) -->
      <div
        v-if="profileType == 'Volunteer' && !award.reedemedUsers?.some(coupon => coupon.code_used && coupon.redeemed_by === profile._id)"
        style="font-family: 'Poppins-SemiBold';display:flex;justify-content:end;"
      >
        <v-btn
          color="#084468"
          @click="couponView(award)"
          class="white--text"
          width="150"
          >Redeem</v-btn
        >
      </div>
    </v-card-text>
    <v-snackbar v-model="snackbar">
      {{ snackMessage }}
    </v-snackbar>
    <edit-awards
      :dialog="editDialog"
      :onClose="onCloseDialog"
      :item="award"
      v-if="editDialog"
    />
    <confirm-delete-dialog
      :deleteDialog="deleteDialog"
      :onClose="onCloseDialog"
      :onConfirm="onDeleteAward"
    />
    <couponsend-dialog
      :dialog="sendCouponDialog"
      :onClose="onCloseDialog"
      :couponWinners="couponWinners"
    />
    <coupon-show-dialog
      :dialog="couponShowDialog"
      :onClose="onCloseDialog"
      :showCouponcodes="showCouponcodes"
      @coupon-revealed="handleCouponRevealed"
    />
  </v-card>
</template>
<script>
import { mapActions, mapGetters, mapState } from "vuex";
import dateFormat from "dateformat";
import EditAwards from "./EditAwards.vue";
import ConfirmDeleteDialog from "@/components/common/ConfirmDeleteDialog.vue";
import EditableAvatar from "../../components/EditableAvatar.vue";
import CouponsendDialog from "./CouponsendDialog.vue";
import CouponShowDialog from "./CouponShowDialog.vue";
export default {
  components: {
    EditAwards,
    ConfirmDeleteDialog,
    EditableAvatar,
    CouponsendDialog,
    CouponShowDialog
  },
  props: {
    award: Object,
    activeConversation: Object,
    hideButtons: {
      type: Boolean,
      default: false
    },
    onClicked: Function,
    eligibles: Array
  },
  data() {
    return {
      snackbar: false,
      snackMessage: null,
      text_collapsed: false,
      collapsed_text: "",
      code: "",
      coupon: false,
      editDialog: false,
      sendCouponDialog: false,
      couponShowDialog: false,
      deleteDialog: false,
      users: [],
      couponWinners: {},
      showCouponcodes: {},
      couponRevealed: false,
      myHours: 0
    };
  },
  methods: {
    ...mapActions("awards", {
      deleteAward: "deleteAward",
      addChat: "chat/addChat",
      getVolunteerHours: "getVolunteerHours",
      getEligibles: "getEligibles",
      fetchAllAwards: "fetchAllAwards",
      initConversationsClient: "chat/initConversationsClient",
      updateConversation: "chat/updateConversation",
      sendMessageNotification: "chat/sendMessageNotification",
      conversationsClient: "chat/conversationsClient"
    }),
    goToDetails() {
      // if (!this.onClicked) {
      //   this.$router.push({
      //     name: "agency-need-details",
      //     query: {
      //       _id: this.award._id,
      //     },
      //   });
      // } else {
      //   this.onClicked(this.award._id);
      // }
    },
    async sendCoupon(awardId, users_id) {
      const couponWinners = {
        awardId,
        users_id
      };
      this.couponWinners = couponWinners;
      this.sendCouponDialog = true;
    },
    async copyCouponCode() {
      try {
        await navigator.clipboard.writeText(this.code);
        this.snackMessage = "Coupon code copied to clipboard!";
        this.snackbar = true;
        setTimeout(() => {
          this.message = null;
        }, 2000);
      } catch (err) {
        console.error("Failed to copy: ", err);
        this.message = "Failed to copy coupon code!";
      }
    },
    goToAgencyProfile(agency) {
      if (agency)
        this.$router.push({
          name: "agency-profile-details",
          params: { id: agency }
        });
    },
    couponView(award) {
      this.showCouponcodes = award;
      this.couponShowDialog = true;
      // this.code = award.couponCode;
      // const redeemedUser = this.award.redeemed.find(
      //   e => e.toString() === this.profile._id
      // );

      // if (redeemedUser) {
      //   this.couponRevealed = redeemedUser.viewed;
      // } else {
      //   this.couponRevealed = false;
      // }
    },
    handleCouponRevealed(couponRevealed) {
      this.couponRevealed = couponRevealed;
    },
    getCollapedText() {
      if (!this.award.description) {
        this.text_collapsed = false;
        return;
      }
      const full_text = this.award.description.trim();
      const textArray = full_text.split(/\r\n|\r|\n/);

      if (textArray.length > 3) {
        this.collapsed_text = textArray.slice(0, 3).join("\n");
        this.text_collapsed = true;
        return;
      }

      // Check for the number of characters
      if (full_text.length > 270) {
        this.collapsed_text = full_text.substring(0, 270);
        this.text_collapsed = true;
        return;
      }

      // If neither condition is met, set collapsed_text to full_text
      this.collapsed_text = full_text;
      this.text_collapsed = false;
    },
    formatDate(date) {
      return dateFormat(
        this.convertTZ(date, this.timezone),
        "mm/dd/yyyy"
      );
    },
    convertTZ(date, tzString) {
      if (!date) return "";
      return new Date(
        (typeof date === "string" ? new Date(date) : date).toLocaleString(
          "en-US",
          {
            timeZone: tzString ? tzString : "America/New_York"
          }
        )
      );
    },
    onEdit() {
      this.editDialog = true;
    },
    onDelete() {
      this.deleteDialog = true;
    },
    onCloseDialog() {
      this.deleteDialog = false;
      this.editDialog = false;
      this.sendCouponDialog = false;
      this.couponShowDialog = false;
    },
    onDeleteAward() {
      this.deleteAward({ _id: this.award._id }).catch(error => {
        console.log(error);
      });
    },
    formatMinutes(value) {
      var hrs = Math.floor(value / 60);
      var str = `${hrs}:`;
      // if (hrs > 9) str += `${hrs}:`;
      // else str += `0${hrs}:`;
      var mins = Math.floor(value % 60);
      if (mins > 9) str += `${mins}`;
      else str += `0${mins}`;
      return str;
    }
  },
  computed: {
    ...mapState("auth", {
      profile: "profile",
      profileType: "type"
    }),
    ...mapGetters({
      timezone: "usersetting/getTimeZone"
    }),
    isCouponRedeemed() {
      var allAwards = [this.award]; 
      // return this.award.redeemed.some(e => e.award_id === this.award._id && e.user_id && e.user_id.toString() === this.profile._id && !e.viewed);
      return allAwards.some(e => e.redeemed.some(r => r && r.toString() === this.profile._id ));
    }
  },
  watch: {
    // eligibles(newValue) {
    //   this.users = newValue.eligibleUsers.filter(
    //     e => e.volunteered_hours >= parseFloat(`${this.award.goal}`) * 60
    //   );
    // }
  },
  mounted() {
    this.handleCouponRevealed();
    //this.getCollapedText();
    // if (
    //   this.profileType == "Agency" ||
    //   (this.award.sponsor_type == "Admin" && this.profileType == "Admin")
    // ) {
    //   this.users = this.eligibles.filter(
    //     e => e.volunteered_hours >= parseFloat(`${this.award.goal}`) * 60
    //   );
    // } else if (
    //   this.profileType == "Admin" &&
    //   this.award.sponsor_type == "Agency"
    // ) {
    //   this.getEligibles()
    //     .then(res => {
    //       this.users = res.filter(
    //         e => e.volunteered_hours >= parseFloat(`${this.award.goal}`) * 60
    //       );
    //     })
    //     .catch(error => {
    //       console.log(error);
    //     });
    // }
  }
};
</script>

<style scoped>
.code-title {
  color: green;
  font-size: 13px;
  font-family: "Poppins-Regular";
  margin: 0px;
  position: absolute;
  left: 660px;
}
.code {
  color: red;
  font-family: "Poppins-Regular";
  font-size: 15px;
  margin: 25px;
}
</style>
